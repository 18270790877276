import { Box } from '@mui/material';
import React from 'react';

export default function NotificationIcon() {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12.0199 20.5319C9.68987 20.5319 7.35987 20.1619 5.14987 19.4219C4.30987 19.1319 3.66987 18.5419 3.38987 17.7719C3.09987 17.0019 3.19987 16.1519 3.65987 15.3919L4.80987 13.4819C5.04987 13.0819 5.26987 12.2819 5.26987 11.8119V8.92188C5.26987 5.20187 8.29987 2.17188 12.0199 2.17188C15.7399 2.17188 18.7699 5.20187 18.7699 8.92188V11.8119C18.7699 12.2719 18.9899 13.0819 19.2299 13.4919L20.3699 15.3919C20.7999 16.1119 20.8799 16.9819 20.5899 17.7719C20.2999 18.5619 19.6699 19.1619 18.8799 19.4219C16.6799 20.1619 14.3499 20.5319 12.0199 20.5319ZM12.0199 3.67188C9.12987 3.67188 6.76987 6.02187 6.76987 8.92188V11.8119C6.76987 12.5419 6.46987 13.6219 6.09987 14.2519L4.94987 16.1619C4.72987 16.5319 4.66987 16.9219 4.79987 17.2519C4.91987 17.5919 5.21987 17.8519 5.62987 17.9919C9.80987 19.3919 14.2399 19.3919 18.4199 17.9919C18.7799 17.8719 19.0599 17.6019 19.1899 17.2419C19.3199 16.8819 19.2899 16.4919 19.0899 16.1619L17.9399 14.2519C17.5599 13.6019 17.2699 12.5319 17.2699 11.8019V8.92188C17.2699 6.02187 14.9199 3.67188 12.0199 3.67188Z"
          fill="#292D32"
        />
        <path
          d="M13.8801 3.93969C13.8101 3.93969 13.7401 3.92969 13.6701 3.90969C13.3801 3.82969 13.1001 3.76969 12.8301 3.72969C11.9801 3.61969 11.1601 3.67969 10.3901 3.90969C10.1101 3.99969 9.81011 3.90969 9.62011 3.69969C9.43011 3.48969 9.37011 3.18969 9.48011 2.91969C9.89011 1.86969 10.8901 1.17969 12.0301 1.17969C13.1701 1.17969 14.1701 1.85969 14.5801 2.91969C14.6801 3.18969 14.6301 3.48969 14.4401 3.69969C14.2901 3.85969 14.0801 3.93969 13.8801 3.93969Z"
          fill="#292D32"
        />
        <path
          d="M12.02 22.8086C11.03 22.8086 10.07 22.4086 9.37002 21.7086C8.67002 21.0086 8.27002 20.0486 8.27002 19.0586H9.77002C9.77002 19.6486 10.01 20.2286 10.43 20.6486C10.85 21.0686 11.43 21.3086 12.02 21.3086C13.26 21.3086 14.27 20.2986 14.27 19.0586H15.77C15.77 21.1286 14.09 22.8086 12.02 22.8086Z"
          fill="#292D32"
        />
      </svg>
    </Box>
  );
}
