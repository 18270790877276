import {createSlice} from "@reduxjs/toolkit";
import {initialLoading5} from "./ui";
import {AppDispatch, RootState} from ".";
import {auth} from "../firebase";
import axios from "axios";
import {BASE_URL} from "../config";

export const initialState = {
  data: {}
} as any;

const TransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    initTransactions(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addTransaction: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteTransaction: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default TransactionSlice.reducer;

export const {initTransactions, addTransaction, deleteTransaction} =
  TransactionSlice.actions;

export const getTransactionsAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(initialLoading5(true));

      const request = {
        url: `${BASE_URL}/all-transactions`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {startAt: new Date(), endAt: new Date().getDate() - 3}
      } as any;

      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initTransactions(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all transactions", error);
    } finally {
      dispatch(initialLoading5(false));
    }
  };
