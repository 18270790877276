// types
import {createSlice} from "@reduxjs/toolkit";

import {AppDispatch} from ".";
import {auth} from "../firebase";
import {authIsLoaded, initialLoading, initialLoading3} from "./ui";
import {signOut} from "firebase/auth";
import {API_KEY, BASE_URL} from "../config";
import axios from "axios";

const localUser = sessionStorage.getItem("user");
// initial state
const initialState = {
  user: localUser,
  token: "",
  config: {},
  userData: null
} as any;

// ==============================|| SLICE - MENU ||============================== //

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initAuth(state, {payload}) {
      state.userData = payload;
    },
    initConfig(state, {payload}) {
      const items: Record<string, any> = {};
      payload.templates.forEach((item: any) => {
        items[item._id] = item;
      });
      const items2: Record<string, any> = {};
      payload.subscriptionPlans.forEach((item: any) => {
        items2[item._id] = item;
      });

      const finalData = {
        templates: items,
        subscriptionPlans: items2,
        appConfig: payload.appConfig
      };
      state.config = finalData;
    },
    modifyTemplate(state, {payload}) {
      state.config.templates[payload._id] = payload;
    },
    deleteTemplate: (state, {payload}) => {
      delete state.config.templates[payload.id];
      return state;
    },
    modifySubscription(state, {payload}) {
      state.config.subscriptionPlans[payload._id] = payload;
    },
    deleteSubscription: (state, {payload}) => {
      delete state.config.subscriptionPlans[payload.id];
      return state;
    },
    addUuid: (state, {payload}) => {
      state.user.uuid = payload;
    }
  }
});

export default authSlice.reducer;

export const {
  initAuth,
  deleteTemplate,
  addUuid,
  initConfig,
  modifyTemplate,
  deleteSubscription,
  modifySubscription
} = authSlice.actions;

export const getUserData =
  ({
    UserID,
    callback,
    onError
  }: {
    UserID: string;
    callback?: () => void;
    onError?: (ev: any) => void;
  }) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(authIsLoaded(true));

      const idToken = await auth.currentUser?.getIdToken();
      const res = await fetch(`${BASE_URL}/admins/${UserID}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        }
      });
      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(initAuth(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        console.log("auth error", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("fetch failed", error);

      onError?.(error);
    } finally {
      dispatch(authIsLoaded(false));
    }
  };

export const updateAuthAsync =
  ({
    formData,
    callback,
    onError
  }: {
    formData: any;
    callback?: () => void;
    onError?: (ev: any) => void;
  }) =>
  async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(authIsLoaded(true));

      const idToken = await auth.currentUser?.getIdToken();
      const res = await fetch(`${BASE_URL}/admins/${formData.id}`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(formData)
      });
      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(initAuth(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        console.log("error updating auth", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      onError?.(error);
    } finally {
      dispatch(authIsLoaded(false));
    }
  };

export const getAppConfigAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(initialLoading3(true));

      const res = await fetch(`${BASE_URL}/config`, {
        mode: "cors",
        headers: {
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        } as any
      });
      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(initConfig(data));
        } else {
          console.log("get config error", error);
        }
      }
    } catch (error) {
      console.log("Error getting config", error);
    } finally {
      dispatch(initialLoading3(false));
    }
  };

export const onLogout = () => async (dispatch: AppDispatch) => {
  try {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    dispatch({type: "USER_LOGGED_OUT"});
    signOut(auth);
  } catch (error) {
    console.log("logout error", error);
  }
};
