// third-party
import {combineReducers, configureStore, AnyAction} from "@reduxjs/toolkit";
import uiReducer from "./ui";
import authReducer from "./auth";
import usersReducer from "./userSlice";
import localforage from "localforage";
import {persistReducer, persistStore} from "redux-persist";
import api from "../api";
import adminReducer from "./adminSlice";
import notificationReducer from "./notificationSlice";
import courseReducer from "./courseSlice";
import subscriptionsReducer from "./subscriptionsSlice";
import fileReducer from "./fileSlice";
import audioReducer from "./audioSlice";
import transactionReducer from "./transactionSlice";
import videoReducer from "./videoSlice";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const persistConfig = {
  key: "imaginaiAdmin",
  storage: localforage,
  blacklist: [api.reducerPath]
};

const reducers = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  users: usersReducer,
  admins: adminReducer,
  notifications: notificationReducer,
  subscriptions: subscriptionsReducer,
  file: fileReducer,
  course: courseReducer,
  audio: audioReducer,
  transaction: transactionReducer,
  video: videoReducer,
  [api.reducerPath]: api.reducer
});

const initialState: Record<string, any> = {
  ui: {
    openItem: ["dashboard"],
    defaultId: "dashboard",
    openComponent: "buttons",
    drawerOpen: false,
    componentDrawerOpen: true,
    sessionExpired: false,
    authIsLoaded: false,
    initialLoading: false,
    initialLoading2: false,
    initialLoading3: false,
    initialLoading4: false,
    initialLoading5: false,
    initialLoading6: false,
    initialLoading7: false,
    initialLoading8: false,
    makingRequest: false,
    app_config: {},
    lang: {
      systemLanguage: "fr",
      languages: [
        {
          lang: "english",
          key: "en"
        },
        {
          lang: "french",
          key: "fr"
        }
      ]
    },
    theme: {
      primary: {main: "#ED0389"},
      secondary: {
        custom: "#325665"
      },
      accent: "#65BF54"
    }
  },
  auth: {
    user: null,
    token: "",
    userData: null,
    config: {
      templates: {},
      subscriptionPlans: {}
    }
  },
  admins: {
    data: {}
  },
  users: {
    data: {},
    firebaseUsers: {}
  },
  notifications: {
    data: {}
  },
  subscriptions: {
    data: {}
  },
  file: {
    data: {},
    labels: {}
  },
  course: {
    data: {}
  },
  audio: {
    data: {}
  },
  video: {
    data: {}
  },
  transaction: {
    data: {}
  }
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "USER_LOGGED_OUT") {
    return {...initialState};
  }
  return reducers(state, action);
};
const persistReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(api.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootStateReturn = () => RootState;
// eslint-disable-next-line no-unused-vars
export declare type Callback = (...args: any[]) => void;

export default persistStore(store);
