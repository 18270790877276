import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import React, {ChangeEvent, useRef, useState} from "react";
import {ModalTransition} from "../../../components/ModalTransition";
import TextInput from "../../../components/TextInput";
import {capitalizeFirstLetter} from "../../../utils/stringUtils";
import {useTranslation} from "react-i18next";
import InputChip from "../../../components/InputChip";
import {AppDispatch} from "../../../store";
import {useDispatch} from "react-redux";
import {uploadVideoAsync} from "../../../store/videoSlice";
import {LoadingButton} from "@mui/lab";

interface Props {
  open: boolean;
  onClose: () => void;
  id?: string;
}
export default function VideoFormModal({open, onClose, id}: Props) {
  const {t} = useTranslation();
  const {palette}: any = useTheme();
  const ref: any = useRef();
  const dispatch: AppDispatch = useDispatch();

  const [form, setForm] = useState<any>({
    title: "",
    description: "",
    tags: "",
    video: null
  });
  const [metaData, setMetaData] = useState({
    size: 0,
    mimetype: "",
    width: 0,
    height: 0,
    duration: 0
  });

  const [loading, setLoading] = useState(false);

  const onChange = (key: string, value: string | any) => {
    setForm((prev: any) => ({...prev, [key]: value}));
  };

  const handleVideo = (ev: ChangeEvent<HTMLInputElement>) => {
    const video = ev?.target?.files?.[0];
    if (video) {
      console.log("selected video", video);
      onChange("video", video);
      setMetaData((prev) => ({
        ...prev,
        size: video.size,
        mimetype: video.type
      }));
    }
  };

  const onClickBox = () => {
    ref.current?.click();
  };

  const callback = () => {
    setLoading(false);
    onClose();
  };
  const onError = (ev: any) => {
    setLoading(false);
  };
  const onCreateVideo = () => {
    setLoading(true);
    const info = {
      file: form.video,
      description: form.description,
      title: form.title,
      tags: form.tags,
      metadata: metaData,
      lang: "en"
    };

    dispatch(
      uploadVideoAsync({
        info: info,
        callback: callback,
        onError: onError
      }) as any
    );
  };

  const handleLoadedMetadata = () => {
    const video = ref.current;

    if (video) {
      setMetaData((prevInfo) => ({
        ...prevInfo,
        width: video.videoWidth ?? 0,
        height: video.videoHeight ?? 0,
        duration: video.duration ?? 0
      }));
    }
  };

  const disabled =
    !form.title.trim() ||
    !form.description.trim() ||
    !form.video ||
    !form.tags?.trim();

  return (
    <Dialog
      open={open}
      TransitionComponent={ModalTransition}
      keepMounted
      onClose={onClose}
      sx={{
        background: "#fff15",
        backdropFilter: "blur(1.5px)",
        "& .MuiPaper-root": {
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          background: "#fff",
          borderRadius: "12px",
          minWidth: "60%",
          maxWidth: "60%"
        }
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          {form.video !== null ? (
            <video
              controls
              width="100%"
              height={300}
              onLoadedMetadata={handleLoadedMetadata}
            >
              <source src={URL.createObjectURL(form.video)} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Box
              sx={{
                borderRadius: 1,
                border: `1px solid ${palette.divider}`,
                bgcolor: palette?.info?.outlet,
                height: 200,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex"
              }}
              onClick={onClickBox}
            >
              <Typography>
                {capitalizeFirstLetter(t("click_upload_video"))}
              </Typography>
            </Box>
          )}
          <input
            accept="video/*"
            id="videoInput"
            type="file"
            ref={ref}
            onChange={handleVideo}
            style={{
              display: "none"
            }}
          />

          <TextInput
            title={capitalizeFirstLetter(t("title"))}
            value={form.title}
            onChange={(el) => onChange("title", el.target.value)}
            // containerStyle={{width: "70%"}}
          />
          <TextInput
            title={capitalizeFirstLetter(t("description"))}
            value={form.description}
            onChange={(el) => onChange("description", el.target.value)}
            // containerStyle={{width: "70%"}}
          />
          <TextInput
            title={capitalizeFirstLetter(t("tags"))}
            value={form.tags}
            onChange={(el) => onChange("tags", el.target.value)}
            // containerStyle={{width: "70%"}}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{pb: 2}}>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{color: "#697586", borderColor: "#697586", width: 148}}
        >
          {capitalizeFirstLetter(t("cancel"))}
        </Button>
        <LoadingButton
          variant="contained"
          sx={{width: 148}}
          disabled={disabled}
          loading={loading}
          onClick={onCreateVideo}
        >
          {capitalizeFirstLetter(t("create"))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
