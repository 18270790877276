import {createSlice} from "@reduxjs/toolkit";
import {initialLoading5} from "./ui";
import {AppDispatch, RootState} from ".";
import {auth} from "../firebase";
import axios from "axios";
import {BASE_URL} from "../config";

export const initialState = {
  data: {}
} as any;

const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    initSubscription(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addSubscription: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteSubs: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default SubscriptionsSlice.reducer;

export const {addSubscription, initSubscription, deleteSubs} =
  SubscriptionsSlice.actions;

export const getAllSubscriptionsAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading5(true));
      const Subscriptions = Object.values(getState().subscriptions.data);

      const request = {
        url: `${BASE_URL}/subscriptions`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Subscriptions.length) {
        const lastOp: any = Subscriptions.sort(
          (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        )[0];
        request.data.createdAt = lastOp.updatedAt;
      }

      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initSubscription(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all subscriptions", error);
    } finally {
      dispatch(initialLoading5(false));
    }
  };
