import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {initialLoading4} from "./ui";
import {API_KEY, BASE_URL} from "../config";
import axios from "axios";

export const initialState = {
  data: {}
} as any;

// ==============================|| SLICE - CONFIGURATIONS ||============================== //

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    initCourse(state, {payload}) {
      for (let course of payload) {
        state.data[course.id] = course;
      }
      return state;
      // console.log("init courses");
      // const items: Record<string, any> = {};
      // payload.forEach((item: any) => {
      //   items[item.id] = item;
      // });
      // console.log("state actions");

      // state.data = {...state?.data, ...items};
      // return state;
    },
    addCourse: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    }
  }
});

export default courseSlice.reducer;

export const {initCourse, addCourse} = courseSlice.actions;

export const getAllCoursesAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const courses = Object.values(getState().course.data);

      const request = {
        method: "POST",
        url: BASE_URL + "/courses",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${API_KEY}`
        },
        data: {}
      } as any;

      if (courses.length) {
        courses.sort(
          (a: any, b: any) =>
            new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
        );
        const lastOp: any = courses[0];

        request.data.updatedAt = lastOp.updatedAt;
      }

      const {data: resData} = await axios(request);

      const {error, data} = resData;

      if (data) {
        return dispatch(
          initCourse(data.map((course: any) => ({...course, id: course._id})))
        );
      }
      throw new Error(error.message);
    } catch (error) {
      console.log("Failed to get course", error);
    }
  };
