// import { lazy } from 'react';

// project import
// import Loadable from './../components/Loadable';
import LoginPage from '../pages/auth/LoginPage';
import ResetPassword from '../pages/auth/ResetPassword';
import MinimalLayout from './../layout/MinimalLayout';

// render - login
// const AuthLogin = Loadable(
//   lazy(() => import('./../pages/authentication/Login'))
// );
// const AuthRegister = Loadable(
//   lazy(() => import('./../pages/authentication/Register'))
// );
// const Unauthorize = Loadable(lazy(() => import('./../components/Unauthorize')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = [
  {
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/reset',
        element: <ResetPassword />
      }
    ]
  }
];

export default LoginRoutes;
