// types
import { createSlice } from '@reduxjs/toolkit';
import i18n from '../i18n-config';
import { AppDispatch } from '.';

export interface ThemeProps {
  primary: any;
  secondary: any;
  accent: string;
}

// initial state
const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  authIsLoaded: false,
  gettingUserInfo: false,
  initialLoading: false,
  initialLoading2: false,
  initialLoading3: false,
  initialLoading4: false,
  initialLoading5: false,
  initialLoading6: false,
  initialLoading7: false,
  initialLoading8: false,
  // pour toutes les requetes
  makingRequest: false,
  //

  componentDrawerOpen: true,
  sessionExpired: false,
  app_config: {},
  lang: {
    systemLanguage: 'fr',
    languages: [
      {
        lang: 'english',
        key: 'en'
      },
      {
        lang: 'french',
        key: 'fr'
      }
    ]
  },
  theme: {
    primary: { main: '#ED0389' },
    secondary: {
      custom: '#000000' + 40
    },
    accent: '#D77910'
  }
};

// ==============================|| SLICE - MENU ||============================== //

const ui = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    toggleSessionExpired: (state, action) => {
      state.sessionExpired = action.payload;
      return state;
    },
    gettingUserInfo: (state, action) => {
      state.gettingUserInfo = action.payload;
      return state;
    },
    initialLoading: (state, action) => {
      state.initialLoading = action.payload;
      return state;
    },
    initialLoading2: (state, action) => {
      state.initialLoading = action.payload;
      return state;
    },
    initialLoading3: (state, action) => {
      state.initialLoading = action.payload;
      return state;
    },
    initialLoading4: (state, action) => {
      state.initialLoading4 = action.payload;
      return state;
    },
    initialLoading5: (state, action) => {
      state.initialLoading5 = action.payload;
      return state;
    },
    initialLoading6: (state, action) => {
      state.initialLoading6 = action.payload;
      return state;
    },
    initialLoading7: (state, action) => {
      state.initialLoading7 = action.payload;
      return state;
    },
    initialLoading8: (state, action) => {
      state.initialLoading8 = action.payload;
      return state;
    },
    makingRequest: (state, action) => {
      state.makingRequest = action.payload;
      return state;
    },
    setLanguage: (state, action) => {
      state.lang = { ...state.lang, systemLanguage: action.payload };
      return state;
    },
    authIsLoaded(state, { payload }) {
      state.authIsLoaded = payload;
    },
    changeTheme: (state, action) => {
      state.theme = action.payload;
      return state;
    },
    addAppconfig(state, { payload }) {
      state.app_config = payload;
    }
  }
});

export default ui.reducer;

export const {
  makingRequest,
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  toggleSessionExpired,
  setLanguage,
  changeTheme,
  authIsLoaded,
  addAppconfig,
  gettingUserInfo,
  initialLoading,
  initialLoading2,
  initialLoading3,
  initialLoading4,
  initialLoading5,
  initialLoading6,
  initialLoading7,
  initialLoading8
} = ui.actions;

export const changeLanguage = (language: string) => (dispatch: AppDispatch) => {
  try {
    // await AsyncStorage.setItem('LANG', language);
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  } catch (error) {
    console.log('change language error', error);
  }
};
