import React from "react";

export default function NoAutorisation() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column"
      }}
    >
      You Dont have access to this module please contact admin
    </div>
  );
}
