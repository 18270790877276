import {
  Box,
  Button,
  Divider,
  Pagination,
  Stack,
  Typography
} from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {useMemo, useState} from "react";
import Search from "../../components/Search/Search";
import {capitalizeFirstLetter} from "../../utils/stringUtils";
import AddNewIcon from "../../assets/icons/AddNewIcon";
import {useTranslation} from "react-i18next";
import EmptyUser from "../users/components/EmptyUser";
import NextIcon from "../../assets/icons/users/NextIcon";
import BackIcon from "../../assets/icons/users/BackIcon";
import {AppDispatch} from "../../store";
import {useDispatch} from "react-redux";
import {publishAudioItemAsync} from "../../store/audioSlice";
import {useNavigate} from "react-router-dom";
import {useVideo} from "../../hooks";
import VideoTable from "./components/VideoTable";
import VideoFormModal from "./components/VideoFormModal";
import {publishVideoItemAsync} from "../../store/videoSlice";
import useAuth from "../../hooks/useAuth";
import NoAutorisation from "../../components/NoAutorisation";

export default function VideoMain() {
  const {videosArray} = useVideo();
  const {t} = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useAuth()
  const [searchValue, setSearchValue] = useState("");
  const [page, setPages] = useState(0);
  const [open, setOpen] = useState(false);

  const pageCount = useMemo(
    () => Math.ceil(videosArray.length / 50),
    [videosArray]
  );

  const videoResult: any[] = useMemo(() => {
    // setPages(0);
    return videosArray
      ?.sort(
        (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      )
      .filter((el: any) =>
        (el?.title + "")?.toLowerCase()?.includes(searchValue.toLowerCase())
      ) as any[];
  }, [videosArray, searchValue]);

  const sliceController = (prev: boolean) => {
    if (prev) {
      if (page === 0) {
        return;
      }
      setPages((prev) => prev - 1);
      return;
    } else {
      if (page === pageCount - 1) {
        return;
      }
      setPages((prev) => prev + 1);
    }
  };

  const onPublish = (videoId: string, published: boolean) => {
    dispatch(publishVideoItemAsync({videoId, published}) as any);
  };

  const modalHandler = () => setOpen((el) => !el);


  if (!user?.access?.video) {
    return <NoAutorisation />;
  }
  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: 1,
        height: `calc(100% - 30px)`,
        overflow: "hidden",
        mt: 2
      }}
    >
      {open ? <VideoFormModal open={open} onClose={modalHandler} /> : null}
      <Grid2
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid2 md={6} py={2} px={2}>
          <Search
            value={searchValue}
            onSearch={(ev) => setSearchValue(ev)}
            containerStyle={{width: "100%"}}
          />
        </Grid2>
        <Grid2
          md={6}
          py={2}
          px={2}
          alignItems={"end"}
          textAlign={"end"}
          justifyContent={"end"}
        >
          <Stack
            direction={"row"}
            spacing={3}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <Typography sx={{fontSize: 16, fontWeight: 400, color: "#697586"}}>
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#202939",
                  marginRight: "4px"
                }}
              >
                {videosArray.length}
              </span>
              {capitalizeFirstLetter(t("video"))}
            </Typography>
            <Typography sx={{fontSize: 16, fontWeight: 400, color: "#697586"}}>
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#202939",
                  marginRight: "4px"
                }}
              >
                {videosArray?.filter((el: any) => el.published)?.length}
              </span>
              {capitalizeFirstLetter(t("published"))}
            </Typography>
            <Button
              startIcon={<AddNewIcon />}
              variant="contained"
              onClick={modalHandler}
            >
              {capitalizeFirstLetter(t("new"))}
            </Button>
          </Stack>
        </Grid2>
      </Grid2>
      <Divider />
      {!videoResult.length ? (
        <EmptyUser />
      ) : (
        <Box
          sx={{
            height: "100%"
          }}
        >
          <VideoTable
            data={videoResult?.slice(page * 50, (page + 1) * 50) as any}
            onView={(id: string) => navigate("audio_details/" + id)}
            onPublish={onPublish}
          />

          <Stack
            direction={"row"}
            sx={{
              height: 70,
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center"
            }}
            // height={30}
            px={1.5}
          >
            <Button
              onClick={() => sliceController(true)}
              startIcon={<NextIcon />}
              sx={{border: "1px solid #D0D5DD", color: "#344054"}}
            >
              {capitalizeFirstLetter(t("previous"))}
            </Button>
            <Pagination
              count={pageCount}
              onChange={(ev, value: number) => {
                setPages(value - 1);
              }}
              page={page + 1}
              variant="outlined"
              shape="rounded"
              hideNextButton
              hidePrevButton
            />
            <Button
              endIcon={<BackIcon />}
              onClick={() => sliceController(false)}
              sx={{border: "1px solid #D0D5DD", color: "#344054"}}
            >
              {capitalizeFirstLetter(t("next"))}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
