/* eslint-disable no-unused-vars */
import {AppDispatch, RootState} from "../store";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {getAllFirebaseUsersAsync, getAllUsersAsync} from "../store/userSlice";
import {getAllAdminsAsync} from "../store/adminSlice";
import {useSelector} from "react-redux";
import {getAppConfigAsync} from "../store/auth";
import {auth} from "../firebase";
import {getAllNotificationsAsync} from "../store/notificationSlice";
import {getAllSubscriptionsAsync} from "../store/subscriptionsSlice";
import {getAllFilesAsync, getAllLabelsFilesAsync} from "../store/fileSlice";
import {getAllCoursesAsync} from "../store/courseSlice";
import {getAllAudioAsync} from "../store/audioSlice";
import {getTransactionsAsync} from "../store/transactionSlice";
import {getAllVideoAsync} from "../store/videoSlice";

export default function useInit() {
  const dispatch: AppDispatch = useDispatch();
  const {
    initialLoading,
    initialLoading2,
    initialLoading3,
    initialLoading4,
    initialLoading5,
    initialLoading6,
    initialLoading7,
    initialLoading8
  } = useSelector((state: RootState) => state.ui);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await auth.currentUser?.getIdToken();
        dispatch(getAllUsersAsync({idToken}) as any),
          dispatch(getAllAdminsAsync({idToken}) as any),
          dispatch(getAppConfigAsync({idToken}) as any),
          dispatch(getAllNotificationsAsync({idToken}) as any),
          dispatch(getAllSubscriptionsAsync({idToken}) as any),
          dispatch(getAllFilesAsync({idToken}) as any),
          dispatch(getAllLabelsFilesAsync({idToken}) as any);
        dispatch(getAllCoursesAsync({idToken}) as any);
        dispatch(getAllAudioAsync({idToken}) as any);
        dispatch(getAllFirebaseUsersAsync({idToken}) as any);
        dispatch(getTransactionsAsync({idToken}) as any);
        dispatch(getAllVideoAsync({idToken}) as any);
      }
    });
  }, [dispatch]);

  return {
    loading:
      initialLoading5 ||
      initialLoading2 ||
      initialLoading3 ||
      initialLoading4 ||
      initialLoading ||
      initialLoading6 ||
      initialLoading7 ||
      initialLoading8
  };
}
