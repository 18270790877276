const textFR = {
  new: "nouveau",
  users: "utilisateurs",
  user: "utilisateur",
  admin: "administrateurs",
  search: "rechercher",
  subscription: "abonnement",
  home: "acceuil",
  view: "voir",
  creation_date: "date de creation",
  name: "nom",
  mobile_number: "numero mobile",
  status: "statut",
  actions: "action",
  no_data_found: "aucune donnée trouvée",
  log_in: "Connexion",
  log_desc: "entrez votre email et votre mot de passe pour vous connecter",
  email: "email",
  password: "mot de passe",
  total_users: "nombre total d'utilisateurs",
  total_active_users: "total d'utilisateurs actifs",
  number_of_subscriptions: "nombre d'abonnements",
  new_users: "nouveaux utilisateurs",
  transactions: "transactions",
  total_subscriptions: "total des abonnements",
  total_bronze_package: "package bronze total",
  total_silver_package: "package argent total",
  next: "suivant",
  précédent: "précédent",
  subscribers: "abonnés",
  package: "paquets",
  subscriptions: "abonnements",
  templates: "modèles",
  new_template: "nouveau modèle",
  new_template_desc: "crée un nouveau modèle",
  edit_template: "modifier le modèle",
  edit_template_desc: "modifier les informations d'un modèle",
  french_version: "version française",
  english_version: "version anglaise",
  general_info: "informations générales",
  fr_title: "titre en français",
  en_title: "titre en anglais",
  fr_instruction: "instruction en français",
  en_instruction: "instruction en anglais",
  fr_description: "description en francais",
  en_description: "description en anglais",
  cancel: "annuler",
  confirm_creation: "confirmer la creation",
  confirm_modification: "confirmer les modifications",
  save: "sauvergarder",
  input_type: "type d'entrée",
  label: "label",
  class: "class",
  target: "cible",
  tag_name: "nom du tag",
  click_upload: "cliquez pour télécharger",
  drag_drop: "ou glisser-dépose",
  png_required: "PNG, JPG | Taille requise",
  size_desc: "350x132px",
  language_info: "information multi-langue",
  back: "retour",
  high_school: "lycéen",
  student: "étudiant",
  all: "tous",
  other: "autres",
  worker: "travailleurs",
  template_details: "details du modèle",
  delete_template: "supprimer le modèle",
  delete_template_desc:
    "êtes-vous sûr de vouloir supprimer ce modèle ? cette action est irréversible",
  yes_delete: "oui supprimer",
  new_admin: "nouvel administrateur",
  new_system_admin_desc: "créer un nouvel administrateur système",
  modify_system_admin: "modifier l'administrateur",
  modify_system_admin_desc:
    "modifier les informations d'un administrateur système",
  first_name: "nom",
  last_name: "prenom",
  username: "nom d'utilisateur",
  user_details: "détails de l'utilisateur",
  staff: "personnelles",
  modify_your_profile_info: "modifier les informations de votre profile",
  remove: "retirer",
  change: "changer",
  templateForm: "formulaire du modèle",
  package_name: "nom de l'abonnement",
  duration: "durée",
  month: "mois",
  year: "année",
  subscription_details: "détails de l'abonnement",
  process_exam: "traiter un sujet",
  describe_image: "décrire l'image",
  speech_to_text: "parole au texte",
  speech_to_instruction: "de la parole à l'instruction",
  summary_text: "résumer le texte",
  translate_text: "traduire le texte",
  suggest_training_test: "suggérer un test d'entraînement",
  extract_text: "extraire le texte",
  suggest_test: "suggérer un test",
  number_of_input: "nombre d'entrée",
  edit_subscription: "modifier l'abonnement",
  new_subscription: "nouvel l'abonnement",
  delete_subscription: "supprimer l'abonnement",
  delete_subscription_desc:
    "êtes-vous sûr de vouloir supprimer ce plan d'abonnement ? cette action est irréversible",
  edit_subscription_desc: "modifier les informations du plan d'abonnement",
  subscription_info: "informations sur l'abonnement",
  features_info: "informations sur les fonctionnalités",
  new_subscription_1: "nouvel abonnement",
  new_subscription_desc: "créer un nouveau plan d'abonnement",
  subscriptionForm: "Formulaire d'abonnement",
  subscription_name: "nom du plan d'abonnement",
  monthly_price: "prix mensuel",
  annual_price: "prix annuel",
  discounted_annual_price: "discounted annual price",
  activated: "activer",
  allow_all: "tout autoriser",
  recommended: "recommender",
  add_template: "ajouter un modèle",
  add_template_desc:
    "sélectionnez le modèle que vous souhaitez ajouter à l'abonnement",
  delete_admin: "supprimer le personnel",
  delete_admin_desc:
    "êtes-vous sûr de vouloir supprimer ce membre du personnel ? cette action est irréversible",
  new_user: "nouvel utilisateur",
  new_user_desc: "créer un nouvel utilisateur pour l'application mobile",
  edit_user: "modifier utilisateur",
  edit_user_desc:
    "modifier les informations d'un utilisateur de l'application mobile",
  add: "ajouter",
  add_subscribers: "ajouter abonnés",
  add_subscribers_desc: "ajouter des abonnés a ce plan d'abonnement",
  selected: "selectionné",
  start_date: "date de debut",
  end_date: "date de fin",
  tap_add: "taper pour ajouter",
  template_playground: "playground du modèle",
  templatePlayground: "playground du modèle",
  date_of_birth: "date de naissance",
  new_notification: "nouvelle notification",
  new_notification_desc:
    "créer une nouvelle notification qui sera envoyée à tous les utilisateurs",
  send: "envoyer",
  notification_text: "texte de notification",
  subscriptionsPlan: "plan souscriptions",
  title: "titre",
  body: "description",
  image: "image",
  total_day_package: "total plan jour",
  total_confort_package: "total plan confort",
  total_pro_package: "total plan pro",
  total_elite_package: "total plan elite",
  week_1: "1 semaine",
  day_1: "1 jour",
  month_1: "1 mois",
  duration_desc: "vous allez ajouter",
  days: "jours",
  files_details: "details du fichiers",
  number_of_days_not_connecting: "nombre de jours sans ouvrir l'application",
  analyse: "analyser",
  notif_send_success: "notification envoyée avec success",
  treated_operations: "operations traitées",
  confirm: "Confirmer",
  schooLevel: "niveau scolaire",
  classes: "classes",
  lessons: "leçons",
  upload_csv: "charger csv",
  create_audio: "créer un audio",
  create_audio_desc:
    "générer un cours audio pour le mobile basé sur les informations suivantes",
  select_notion: "sélectionner la notion",
  level_of_study: "niveau d'études",
  subject: "sujet",
  select_notion_desc: "sélectionnez une notion dans cette liste de notions",
  select_on_what_content_to_generate: "sélectionner sur quel contenu générer",
  generate_audio: "générer de l'audio",
  previous: "precedent",
  category: "categorie",
  metrics: "metrique",
  audio_details: "details de l'audio",
  number_of_daily_subscriptions: "nombre d'abonnements journalier",
  number_of_pro_subscriptions: "nombre d'abonnements pro",
  number_of_confort_subscriptions: "nombre d'abonnements confort",
  total_gabon_users: "total utilisateurs gabon",
  total_togo_users: "total utilisateurs togo",
  amount_paid: "montant payé",
  creation_date_last_refresh: "date de creatin / date de derniere connexion",
  creation_interval: "interval de creation",
  reset: "reinitialiser"
};

export default textFR;
