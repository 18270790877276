import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {initialLoading4, makingRequest} from "./ui";
import {API_KEY, BASE_URL} from "../config";
import {getAuth} from "firebase/auth";
import axios from "axios";

export const initialState = {
  data: {}
} as any;

// ==============================|| SLICE - CUSTOMERS ||============================== //

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    initNotification(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addNotification: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteNotification: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default notificationSlice.reducer;

export const {initNotification, addNotification, deleteNotification} =
  notificationSlice.actions;

export const createNotificationAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/notification`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(addNotification(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating Notification", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const getAllNotificationsAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading4(true));
      const Notifications = Object.values(getState().notifications.data);

      const request = {
        url: `${BASE_URL}/allNotification`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Notifications.length) {

        const lastOp: any = Notifications.sort(
          (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        )[0];

        request.data.createdAt = lastOp.createdAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initNotification(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all notifications", error);
    } finally {
      dispatch(initialLoading4(false));
    }
  };

export const specificNotificationAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/sendNotifications`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data_to_send)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(addNotification(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating Notification", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const timelineNotificationAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/recurrentNotifications`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data_to_send)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("finish creating Notification data", data);
          dispatch(addNotification(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating Notification", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };
