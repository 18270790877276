import { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
  CircularProgress,
  Backdrop
} from '@mui/material';
import useGetColors from '../../hooks/useGetColor';
import AppInput from '../../components/AppInput';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import i18n from '../../i18n-config';
import HideIcon from '../../assets/icons/HideIcon';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LanguageSelector from './LanguageSelector';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/auth';
export default function LoginPage() {
  const colors: any = useGetColors();
  const authIsLoaded = useSelector((state: RootState) => state.ui.authIsLoaded);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formHandler = (key: string) => (value: string) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const onLoginHandler = async () => {
    try {
      setLoading(true);
      const user = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      dispatch(
        getUserData({
          UserID: user?.user.uid
          // callback: callBack
        }) as any
      );
    } catch (error) {
      setError(error as any);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || authIsLoaded}
      >
        <CircularProgress color="success" />
      </Backdrop>

      <form>
        <AppInput
          title={capitalizeFirstLetter(i18n.t('email'))}
          value={formData.email}
          onChange={formHandler('email')}
          type="email"
          containerStyle={{
            marginBottom: '28px',
            width: 386
          }}
        />
        <AppInput
          title={capitalizeFirstLetter(i18n.t('password'))}
          value={formData.password}
          onChange={formHandler('password')}
          type={show ? 'text' : 'password'}
          endIcon={
            <IconButton onClick={() => setShow((prev) => !prev)}>
              {!show ? <HideIcon /> : <RemoveRedEyeOutlinedIcon />}
            </IconButton>
          }
          containerStyle={{
            width: 386
          }}
        />
      </form>

      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: colors.info.text2,
          mt: 4,
          mb: 4,
          width: 386
        }}
      >
        {capitalizeFirstLetter(i18n.t('log_desc'))}
      </Typography>

      {/* loading button */}

      <div>
        <Button
          onClick={onLoginHandler}
          disabled={
            !validateEmailAndPassword(formData.email, formData.password)
          }
          sx={{
            bgcolor: colors.primary.main,
            color: 'white',
            width: 386
          }}
        >
          {capitalizeFirstLetter(i18n.t('log_in'))}
        </Button>
        {error ? (
          <Typography sx={{ mt: 2, color: 'red', textAlign: 'center' }}>
            {' '}
            {'please verify your login credentials'}
          </Typography>
        ) : null}
      </div>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <LanguageSelector />
    </Box>
  );
}

function validateEmailAndPassword(email: string, password: string): boolean {
  if (!email || !password) {
    return false;
  }
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(email)) {
    return false;
  }
  if (password.length < 8) {
    return false;
  }
  return true;
}
