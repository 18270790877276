import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {auth} from "../firebase";
import {BASE_URL} from "../config";
import axios from "axios";
import {initialLoading, makingRequest} from "./ui";
import {getAuth} from "firebase/auth";

export const initialState = {
  data: {},
  userDetails: {discussions: {}, operations: {}},
  firebaseUsers: {}
} as any;

// ==============================|| SLICE - USER ||============================== //

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    initUser(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item.id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    initFirebaseUser(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item.uid] = item;
      });
      state.firebaseUsers = {...state?.firebaseUsers, ...items};
      return state;
    },
    addUser: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteUser: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    },
    initUserDiscussion(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.userDetails.discussions = items;
      return state;
    },
    initUserOperations(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.userDetails.operations = items;
      return state;
    }
  }
});

export default userSlice.reducer;

export const {
  addUser,
  initUser,
  deleteUser,
  initUserDiscussion,
  initUserOperations,
  initFirebaseUser
} = userSlice.actions;

export const createCustomerAsync =
  (
    formData: any,
    callback?: (id: string) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(makingRequest(true));

      const authUser = getState().auth.user;
      const idToken = await getAuth().currentUser?.getIdToken();
      const res = await fetch(`${BASE_URL}/users`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(formData)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(addUser(data));
          callback?.(data?._id);
          return;
        }

        onError?.(error.message);
        throw new Error(error.message);
      } else {
        onError?.("veuillez changer de numéro");
      }
    } catch (err: any) {
      console.log("error when creating customer", err);
      onError?.(err);
    } finally {
      dispatch(makingRequest(false));
    }
  };
export const updateCustomerAsync =
  (
    formData: any,
    callback?: (id: string) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));
      const idToken = await auth.currentUser?.getIdToken();
      const data_to_send = {
        ...formData,
        updatedAt: new Date()
      };
      console.log("data to send", data_to_send);

      // if (data_to_send.image) {
      //   data_to_send.photoURL = await uploadImage({
      //     file: data_to_send.photoURL,
      //     directory: 'admin'
      //   });
      //   delete data_to_send.photoURL;
      // }
      delete data_to_send.photoURL;
      delete data_to_send.id;

      const res = await fetch(`${BASE_URL}/users/${formData.id}`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data_to_send)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("the return data", data);
          dispatch(addUser(data));
          callback?.(data?._id);
          return;
        }
        onError?.(error.message);
        console.log("message", error.message);

        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error updating user", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const getAllUsersAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading(true));
      const Users = Object.values(getState().users?.data ?? {});

      const request = {
        url: `${BASE_URL}/allUsers`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Users?.length) {
        const lastOp: any = Users.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        )[0];

        request.data.createdAt = lastOp.createdAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        console.log("user data", data);
        dispatch(initUser(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all users", error);
    } finally {
      dispatch(initialLoading(false));
    }
  };

export const getUserDiscussionsAsync =
  (userID: string, callback?: () => void, onSucess?: (ev: any) => void) =>
  async () => {
    try {
      callback?.();
      const idToken = await auth.currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/discussions/user`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({userID})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          const ordered = data.map((el: any) => ({
            ...el,
            id: el._id
          }));
          console.log("the discussions", ordered);
          onSucess?.(ordered);
          // dispatch(initUserDiscussion(ordered));
          return;
        }
        console.log("error getting discussions", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting discussions", error);
    } finally {
      callback?.();
    }
  };

export const getUserOperationsAsync =
  (
    userID: string,
    createdAt: string | any,
    callback?: () => void,
    onSucess?: (data: any) => void,
    onError?: (err: any) => void
  ) =>
  async () => {
    try {
      callback?.();
      const idToken = await auth.currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/operations/user`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify({userID, createdAt})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("operations", data);
          onSucess?.(data);
          return;
        }
        console.log("error getting operations", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting operations", error);
      onError?.(error);
    } finally {
      // dispatch(gettingUserInfo(false));
      callback?.();
    }
  };

export const getAllFirebaseUsersAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading(true));
      const Users = Object.values(getState().users?.firebaseUsers ?? {});

      const request = {
        url: `${BASE_URL}/firebase-users`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Users?.length) {
        const lastOp: any = Users.sort((a: any, b: any) => {
          const dateA = new Date(a.metadata?.creationTime ?? 0).getTime();
          const dateB = new Date(b.metadata?.creationTime ?? 0).getTime();

          return dateB - dateA;
        })[0];

        request.data.creationTime = lastOp?.metadata?.creationTime;
      }
      const {data: resData} = await axios(request);
      const {data, error} = resData;
      if (data) {
        console.log("firebase users", data);

        dispatch(initFirebaseUser(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting firebase users", error);
    } finally {
      dispatch(initialLoading(false));
    }
  };

export const searchUsersAsync =
  (
    data: any,
    callback?: () => void,
    onSucess?: (data: any) => void,
    onError?: (err: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      const res = await fetch(`${BASE_URL}/search-users`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        console.log("res are here", data);

        if (data) {
          console.log("search result", data);
          dispatch(initUser(data));
          onSucess?.(data);
          return;
        }
        console.log("error getting search result", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting search result", error);
      onError?.(error);
    } finally {
      callback?.();
    }
  };
