import {
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  SxProps
} from '@mui/material';

interface Props {
  title?: string;
  id?: string;
  name?: string;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (ev: any) => any;
  touched?: any;
  errors?: any;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  multiline?: boolean
  onChange?: (ev: any) => void;
  value?: string | number | null;
  containerStyle?: SxProps;
  showSearch?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  inputStyle?: React.CSSProperties | SxProps;
}

export default function TextInput({
  title,
  id,
  onChange,
  onBlur,
  value,
  name,
  endIcon,
  startIcon,
  containerStyle,
  placeholder,
  errors,
  touched,
  type,
  disabled,
  inputStyle,
  required = false,
  multiline=false
}: Props) {
  return (
    <Stack spacing={1} sx={{...containerStyle}}>
      {title && (
        <InputLabel htmlFor={name}>
          {" "}
          {title} {required ? <span style={{color: "red"}}>*</span> : null}{" "}
        </InputLabel>
      )}
      <OutlinedInput
        fullWidth
        sx={{...inputStyle}}
        error={Boolean(touched?.[name as string] && errors?.[name as string])}
        id={name}
        multiline={multiline}
        type={type}
        value={value}
        name={name}
        onBlur={onBlur}
        onChange={(e) => {
          onChange?.(e);
        }}
        startAdornment={
          startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null
        }
        endAdornment={<InputAdornment position="end">{endIcon}</InputAdornment>}
        placeholder={placeholder}
        disabled={disabled}
      />

      {touched?.[name as string] && errors?.[name as string] && (
        <FormHelperText error id={id}>
          {errors?.[name as string]}
        </FormHelperText>
      )}
    </Stack>
  );
}
