import { ReactNode, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AppDispatch, RootState } from '../../store';
import i18n from '../../i18n-config';
import { changeLanguage } from '../../store/ui';
import Transitions from '../../components/Transitions';
import { paperBoxShadow } from '../../config';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import FlagEn2 from '../../assets/icons/FlagEn2';
import FlagFr2 from '../../assets/icons/FlagFr2';
import Drop2Icon from '../../assets/icons/Drop2Icon';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const keyIcon: Record<string, ReactNode> = {
  fr: <FlagFr2 />,
  en: <FlagEn2 />
};

const LangItem = ({
  lang,
  icon,
  onClick
}: {
  lang: string;
  icon: ReactNode;
  onClick: () => void;
}) => (
  <ListItemButton onClick={onClick}>
    <ListItemAvatar>
      <Avatar
        sx={{
          color: 'success.main',
          // bgcolor: 'success.lighter',
          height: 24,
          width: 24,
          p: 1,
          bgcolor: '#fff',
          overflow: 'h'
        }}
        variant="square"
      >
        {icon}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography variant="h6">
          <Typography
            component="span"
            variant="subtitle1"
            textTransform="capitalize"
            color={'#637083'}
          >
            {i18n.t(lang)}
          </Typography>{' '}
        </Typography>
      }
    />
  </ListItemButton>
);

// ==============================|| HEADER CONTENT - LANGUAGE ||============================== //

const LanguageSelector = () => {
  const theme: any = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const lang = useSelector((state: RootState) => state.ui.lang);
  const dispatch: AppDispatch = useDispatch();
  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const hangleChangeLanguage = (lang: string) => {
    dispatch(changeLanguage(lang) as any);
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
      <Box
        sx={{
          color: 'text.primary',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          borderRadius: 1,
          border: '1px solid',
          borderColor: '#CED2DA'

          // bgcolor: open ? iconBackColorOpen : iconBackColor
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Box height={24} width={24}>
          {keyIcon[lang?.systemLanguage]}{' '}
        </Box>
        <Typography
          variant="h6"
          fontWeight={600}
          ml={2}
          textTransform="capitalize"
          sx={{
            color: 'info.grayNeutra300',
            mr: 2
          }}
        >
          {lang?.systemLanguage === 'fr'
            ? i18n.t('Francais(FR)')
            : i18n.t('English(UK)')}
        </Typography>
        <Drop2Icon />
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: paperBoxShadow,
                width: '100%',
                minWidth: 180,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    '& .MuiListItemButton-root': {
                      py: 0.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': {
                        ...actionSX,
                        position: 'relative'
                      }
                    }
                  }}
                >
                  {lang?.languages?.map((item: any) => (
                    <LangItem
                      lang={item.lang}
                      key={item.key}
                      icon={keyIcon[item.key]}
                      onClick={() => hangleChangeLanguage(item.key)}
                    />
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default LanguageSelector;
