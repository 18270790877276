export function groupAndSortElementsByCategory(
  elements: Record<string, any>[]
): { title: string; data: Record<string, any>[] }[] {
  const groupedElements: { [key: string]: Record<string, any>[] } = {};

  // Regrouper les éléments par date
  for (const element of elements) {
    const category = element.category;
    if (groupedElements[category]) {
      groupedElements[category].push(element);
    } else {
      groupedElements[category] = [element];
    }
  }

  // Trier les éléments de chaque groupe du plus récent au plus ancien
  const sortedGroups: { title: string; data: Record<string, any>[] }[] = [];
  for (const cat in groupedElements) {
    sortedGroups.push({
      title: cat,
      data: groupedElements[cat].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
    });
  }

  return sortedGroups;
}

export function fuseArrays(
  arrayA: any[],
  arrayB: any[]
): (any & any & {hasDocument: boolean})[] {
  const bMap = new Map<string, any>(arrayB.map((b) => [b.id, b]));

  return arrayA.map((a) => {
    const matchedB = bMap.get(a.uid);
    if (matchedB) {
      return {...a, ...matchedB, hasDocument: true};
    } else {
      return {...a, hasDocument: false};
    }
  });
}