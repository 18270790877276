import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {initialLoading4, makingRequest} from "./ui";
import {API_KEY, BASE_URL} from "../config";
import {getAuth} from "firebase/auth";
import axios from "axios";

export const initialState = {
  data: {}
} as any;

// ==============================|| SLICE - CUSTOMERS ||============================== //

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    initAudio(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addAudio: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteAudio: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default audioSlice.reducer;

export const {initAudio, addAudio, deleteAudio} = audioSlice.actions;

export const publishAudioItemAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/audio/publish`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(addAudio(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating text to audio", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const generateAudioItemAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/audio/create`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          dispatch(addAudio(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating text to audio", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const generateTextToAudio =
  (
    data_to_send: any,
    callback?: (ev: any) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/audio/generate`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          callback?.(data);
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error creating text to audio", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const generateProgramPlan =
  (
    data_to_send: any,
    callback?: (ev: any) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/programs/generate-plan`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          callback?.(data);
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error generating program", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const generateContentForAudio =
  (
    data_to_send: any,
    callback?: (ev: any) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));

      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/audio/text/generate`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          callback?.(data);
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error generating content", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const getAllAudioAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const Audio = Object.values(getState().audio.data);

      const request = {
        url: `${BASE_URL}/audio`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Audio.length) {
        const lastOp: any = Audio.sort(
          (a: any, b: any) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)
        )[0];

        request.data.updatedAt = lastOp.updatedAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initAudio(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all audio", error);
    }
  };
