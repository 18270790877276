import React from 'react';

export default function AddNewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M13.4917 1.6665H6.50835C3.47502 1.6665 1.66669 3.47484 1.66669 6.50817V13.4832C1.66669 16.5248 3.47502 18.3332 6.50835 18.3332H13.4834C16.5167 18.3332 18.325 16.5248 18.325 13.4915V6.50817C18.3334 3.47484 16.525 1.6665 13.4917 1.6665Z"
        fill="white"
      />
      <path
        d="M15 9.375H10.625V5C10.625 4.65833 10.3417 4.375 10 4.375C9.65833 4.375 9.375 4.65833 9.375 5V9.375H5C4.65833 9.375 4.375 9.65833 4.375 10C4.375 10.3417 4.65833 10.625 5 10.625H9.375V15C9.375 15.3417 9.65833 15.625 10 15.625C10.3417 15.625 10.625 15.3417 10.625 15V10.625H15C15.3417 10.625 15.625 10.3417 15.625 10C15.625 9.65833 15.3417 9.375 15 9.375Z"
        fill="white"
      />
    </svg>
  );
}
