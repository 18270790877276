import { useRef, useState, ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

// project import

import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';

// assets

import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { AppTheme } from '../../../../../themes';
import Transitions from '../../../../../components/Transitions';
import MainCard from '../../../../../components/MainCard';
import { useDispatch } from 'react-redux';
import { onLogout } from '../../../../../store/auth';
import { paperBoxShadow } from '../../../../../config';
import i18n from '../../../../../i18n-config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import LogoutIcon from '../../../../../assets/icons/LogoutIcon';
import ProfileIcon from '../../../../../assets/icons/ProfileIcon';
import DropdownIcon from '../../../../../assets/icons/DropdownIcon';
import useAuth from '../../../../../hooks/useAuth';

// tab panel wrapper
interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
  [key: string]: any;
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef: any = useRef(null);
  const { user } = useAuth();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const onProfile = () => {
    // dispatch(activeItem({ openItem: [8] }));
    navigate('/profile');
  };
  const log = () => dispatch(onLogout() as any);
  return (
    <Box>
      <Button
        size="small"
        startIcon={
          <Avatar src={user?.photoURL + ''} sx={{ height: 32, width: 32 }} />
        }
        endIcon={<DropdownIcon />}
        sx={{ color: '#292D3290' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {user?.firstname}
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow:
                    'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)',
                  background: '#FFF',
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box px={3} py={2}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={onProfile}
                      spacing={2}
                    >
                      <ProfileIcon />
                      <Typography>Profile</Typography>
                    </Stack>
                  </Box>
                </ClickAwayListener>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ px: 3, pb: 2 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={log}
                      spacing={2}
                    >
                      <LogoutIcon />
                      <Typography>Se deconnecter</Typography>
                    </Stack>
                  </Box>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
    // <Box sx={{ flexShrink: 0, ml: 0.75 }}>
    //   <ButtonBase
    //     sx={{
    //       p: 0.25,
    //       bgcolor: open ? iconBackColorOpen : 'transparent',
    //       borderRadius: 1,
    //       '&:hover': { bgcolor: 'secondary.lighter' }
    //     }}
    //     aria-label="open profile"
    //     ref={anchorRef}
    //     aria-controls={open ? 'profile-grow' : undefined}
    //     aria-haspopup="true"
    //     onClick={handleToggle}
    //   >
    //     <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
    //       <Avatar
    //         alt="profile user"
    //         src={'/user.png'}
    //         sx={{ width: 32, height: 32 }}
    //       />
    //       <Typography
    //         sx={{ fontSize: 16, fontWeight: 600, color: 'info.text1' }}
    //       >
    //         {userData?.ownerName ?? 'N/A'}
    //       </Typography>
    //     </Stack>
    //   </ButtonBase>
    // </Box>
  );
};

export default Profile;
