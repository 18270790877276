/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  CircularProgress,
  Toolbar,
  useMediaQuery
} from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import { AppDispatch, RootState } from '../../store';
import { openDrawer } from '../../store/ui';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { getUserData, onLogout } from '../../store/auth';
import useInit from '../../hooks/useInit';
import CustomBackdrop from '../../components/CustomBackdrop';
import useAuth from '../../hooks/useAuth';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const { user } = useAuth();
  const { loading } = useInit();

  const makingRequest = useSelector(
    (state: RootState) => state.ui.makingRequest
  );

  const { drawerOpen } = useSelector((state: RootState) => state.ui);
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const _onAuthStateChanged = useCallback(
    (userState: any) => {
      if (userState) {
        dispatch(
          getUserData({
            UserID: userState.uid
          }) as any
        );
      } else {
        dispatch(onLogout() as any);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    try {
      const subscriber = onAuthStateChanged(auth, _onAuthStateChanged);
      return subscriber;
    } catch (error) {
      console.log('error from auth state change', error);
    }
  }, [_onAuthStateChanged]);

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  if (!user) {
    return <Navigate to="/login" />;
  }
  // if (loading) {
  //   return <CustomBackdrop open={loading} />;
  // }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 4000 }}
        open={makingRequest}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <Header
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        show={matchDownLG}
      />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          bgcolor: 'info.outlet',
          width: '100%',
          height: '100vh',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Toolbar />
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 60px)',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
