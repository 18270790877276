// material-ui
import { useTheme, SxProps, styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors,
  Chip
} from '@mui/material';
import useGetColors from '../../../hooks/useGetColor';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import useAuth from '../../../hooks/useAuth';
// import Logo from '../../../../components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, sx }: { theme: any; open: boolean; sx?: SxProps }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
  ...sx
}));

const DrawerHeader = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const colors = useGetColors();

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{ bgcolor: colors.primary.main }}
    >
      <ListItem
        disablePadding
        secondaryAction={
          <Chip
            label={capitalizeFirstLetter(user?.role + '')}
            sx={{
              bgcolor: user?.status === 'admin' ? '#FFF6ED' : '#F9F5FF',
              color: user?.status === 'admin' ? '#C4320A' : '#6941C6',
              fontSize: 12,
              fontWeight: 500
            }}
          />
        }
      >
        <ListItemAvatar>
          <Avatar src={user?.photoURL} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              sx={{
                color: 'info.contrastText',
                fontSize: 20,
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%'
              }}
            >
              {capitalizeFirstLetter(user?.username)}
            </Typography>
          }
        />
      </ListItem>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;

function calculateAverageRating(ratings: { [key: string]: number }): number {
  let sum = 0;
  let count = 0;

  for (const key in ratings) {
    // eslint-disable-next-line no-prototype-builtins
    if (ratings.hasOwnProperty(key)) {
      sum += parseInt(key) * ratings[key];
      count += ratings[key];
    }
  }

  if (count === 0) {
    return 0;
  } else {
    return sum / count;
  }
}
