// ==============================|| DRAWER CONTENT ||============================== //

// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import SimpleBar from '../../../components/SimpleBar';
import NavItem from './NavItem';
import { siderbarRoutes } from '../../../routes/MainRoutes';
import useGetColors from '../../../hooks/useGetColor';
import LogoutIcon from '../../../assets/icons/LogoutIcon';
import i18n from '../../../i18n-config';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { onLogout } from '../../../store/auth';
import { useDispatch } from 'react-redux';

const DrawerContent = () => {
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state: RootState) => state.ui);
  const colors = useGetColors();

  const handleLogout = async () => {
    dispatch(onLogout() as any);
  };

  return (
    <SimpleBar
      sx={{
        height: '100%',
        bgcolor: colors.primary.main,
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          pt: 2,
          height: 'calc(100vh - 80px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box
          px={2}
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'column'}
        >
          {siderbarRoutes
            // .slice(0, 6)
            .map((item, index) => (
              <NavItem key={index} item={item} level={1} />
            ))}
        </Box>

        <List>
          {/* <Box
            px={2}
            justifyContent={'space-between'}
            display={'flex'}
            flexDirection={'column'}
          >
            {siderbarRoutes.slice(6, 9).map((item, index) => (
              <NavItem key={index} item={item} level={1} />
            ))}
          </Box> */}
          <ListItem disablePadding sx={{ px: 3 }} onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  letterSpacing: '0.374px',
                  color: '#FFFFFF',
                  ml: 3
                }}
              >
                {drawerOpen ? i18n.t('logout') : null}
              </Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SimpleBar>
  );
};

export default DrawerContent;
