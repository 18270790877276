import {lazy} from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

import HomeIcon from "../assets/icons/drawer/HomeIcon";
import HomeActiveIcon from "../assets/icons/drawer/HomeActiveIcon";
import VideoMain from "../pages/vIdeos/VideoMain";

const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Program = Loadable(lazy(() => import("../pages/program/Program")));
const Configuration = Loadable(
  lazy(() => import("../pages/configuration/Configuration"))
);
const Users = Loadable(lazy(() => import("../pages/users/Users")));
const Admin = Loadable(lazy(() => import("../pages/admin/Admin")));
const Profile = Loadable(lazy(() => import("../pages/profile/Profile")));
const Notifications = Loadable(
  lazy(() => import("../pages/notification/NotificationPage"))
);

const UserDetails = Loadable(lazy(() => import("../pages/users/UserDetails")));
const Files = Loadable(lazy(() => import("../pages/files/Files")));

const TemplateDetails = Loadable(
  lazy(() => import("../pages/templates/TemplatesDetails"))
);

const TemplateForm = Loadable(
  lazy(() => import("../pages/templates/TemplatesForm"))
);
const TemplatePlayground = Loadable(
  lazy(() => import("../pages/templates/TemplatePlayground"))
);

const Subscription = Loadable(
  lazy(() => import("../pages/subscriptions/Subscriptions"))
);

const SubscriptionPlanDetails = Loadable(
  lazy(() => import("../pages/subscriptionPlan/SubscriptionDetails"))
);

const SubscriptionPlanForm = Loadable(
  lazy(() => import("../pages/subscriptionPlan/SubscriptionForm"))
);

const AudioDetails = Loadable(
  lazy(() => import("../pages/program/audio/AudioDetails"))
);

const Competition = Loadable(
  lazy(() => import("../pages/competition/Competition"))
);
const TransactionHome = Loadable(
  lazy(() => import("../pages/transactions/TransactionsHome"))
);
// ==============================|| MAIN ROUTING ||============================== //

export const siderbarRoutes: any[] = [
  {
    id: 0,
    path: "/",
    name: "home",
    title: "home",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 1,
    path: "/users",
    name: "users",
    title: "users",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 2,
    path: "/subscriptions",
    name: "subscriptions",
    title: "subscriptions",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 3,
    path: "/notifications",
    name: "notifications",
    title: "notifications",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 4,
    path: "/staff",
    name: "staff",
    title: "staff",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },

  {
    id: 5,
    path: "/configurations",
    name: "configurations",
    title: "configurations",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 6,
    path: "/files",
    name: "files",
    title: "files",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 7,
    path: "/program",
    name: "program",
    title: "program",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 8,
    path: "/transactions",
    name: "transactions",
    title: "transactions",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 9,
    path: "/video",
    name: "video",
    title: "video",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  },
  {
    id: 10,
    path: "/competition",
    name: "competition",
    title: "competition",
    icon: HomeIcon,
    activeIcon: HomeActiveIcon
  }
];

const MainRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/users/user_details/:userId",
        element: <UserDetails />
      },
      {
        path: "/staff",
        element: <Admin />
      },
      {
        path: "/configurations",
        element: <Configuration />
      },
      {
        path: "/configurations/subscriptionsPlan/subscription_details/:id",
        element: <SubscriptionPlanDetails />
      },
      {
        path: "/configurations/subscriptionsPlan/subscriptionForm/:id",
        element: <SubscriptionPlanForm />
      },
      {
        path: "/configurations/templates/template_details/:id",
        element: <TemplateDetails />
      },
      {
        path: "/configurations/templates/templateForm/:id",
        element: <TemplateForm />
      },
      {
        path: "/configurations/templates/TemplatePlayground/:id",
        element: <TemplatePlayground />
      },

      {
        path: "/files",
        element: <Files />
      },
      {
        path: "/subscriptions",
        element: <Subscription />
      },
      {
        path: "/notifications",
        element: <Notifications />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/program",
        element: <Program />
      },
      {
        path: "/program/audio_details/:id",
        element: <AudioDetails />
      },
      {
        path: "/transactions",
        element: <TransactionHome />
      },
      {
        path: "/video",
        element: <VideoMain />
      },
      {
        path: "/competition",
        element: <Competition />
      }
    ]
  }
];

export default MainRoutes;
