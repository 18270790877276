import { useSelector } from 'react-redux';
import { RootState } from '../store';

export default function useAuth() {
  const user = useSelector((state: RootState) => state.auth.userData);

  return {
    user
  };
}
