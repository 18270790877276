export function capitalizeFirstLetter(value: string) {
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
}

export function stringReduceAddDots(string: string, limit: number) {
  if (String(string).length <= limit) {
    return string;
  } else {
    return `${String(string).slice(0, limit)}...`;
  }
}

export function formatLocation(inputString: string) {
  var parts = inputString.split(', ');

  if (parts.length >= 2) {
    return parts[1] + ', ' + parts[2];
  } else {
    return inputString; // Return the original string if it cannot be split as expected
  }
}

const generateKeywordsByName = (name: string) => {
  let keywords = [] as string[];
  const letters = name.split('');
  letters.reduce((previous, current) => {
    previous += current;
    keywords.push(previous);
    return previous;
  }, '');

  return keywords.map((keyword) => keyword.toLowerCase());
};

export const generateKeywordsByNames = (name: string) => {
  let keywords = [] as string[];
  let words = name.split(' ');
  for (const word of words) {
    let ret = generateKeywordsByName(word);
    keywords = keywords.concat(ret);
  }

  words.reduce((previous, current) => {
    if (previous !== '') {
      previous += ' ' + current;
      let result = generateKeywordsByName(previous);
      keywords = keywords.concat(result);
      return previous;
    }
    return current;
  }, '');

  return [...(new Set(keywords) as any)];
};

export function getTimestampOfPreviousWeekday(): number {
  const currentDate = new Date();
  let daysToSubtract = 0;

  switch (currentDate.getDay()) {
    case 0:
      daysToSubtract = 2;
      break;
    case 1:
      daysToSubtract = 3;
      break;
    case 2:
      daysToSubtract = 4;
      break;
    case 3:
      daysToSubtract = 5;
      break;
    case 4:
      daysToSubtract = 6;
      break;
    case 5:
      daysToSubtract = 0;
      break;
    case 6:
      daysToSubtract = 1;
      break;
  }

  // Calculate the timestamp of the previous weekday
  const previousWeekdayTimestamp =
    currentDate.getTime() - daysToSubtract * 24 * 60 * 60 * 1000;

  return previousWeekdayTimestamp;
}
