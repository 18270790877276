import { Box, Stack, Grid, Typography } from '@mui/material';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useGetColors from '../../hooks/useGetColor';
import TitleIcon from '../../assets/icons/TitleIcon';
import DonutTop from '../../assets/icons/DonutTop';
import DonutBottom from '../../assets/icons/DonutBottom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import AuthLay from '../../assets/icons/auth/AuthLay';
import BugleIcon from '../../assets/icons/auth/BugleIcon';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const colors = useGetColors();
  const user = useSelector((state: RootState) => state.auth);

  if (user?.userData) {
    return <Navigate to="/" />;
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        display: 'flex'
      }}
    >
      <Grid
        item
        sx={{
          bgcolor: colors.primary.main
        }}
        xs={0}
        md={4}
      >
        {/* <Box
          sx={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BugleIcon />
          <AuthLay />
        </Box> */}
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 4,
          height: 'calc(100% -250px)'
        }}
      >
        <Stack
          direction={'row'}
          mb={4}
          spacing={2}
          sx={{ alignItems: 'center', mr: 24 }}
        >
          {/* <Box
            sx={{
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <DonutTop />

            <DonutBottom />
          </Box> */}
          {/* <TitleIcon /> */}
        </Stack>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default MinimalLayout;
