// ==============================|| THEME CONFIG  ||============================== //
//
const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;
export const drawerClosedWidth = 90;
export const paperBoxShadow = 'rgba(145, 158, 171, 0.16) 0px 4px 8px';

const PROD_URL = 'https://app.imaginai.io/api/v1';
const DEV_URL = 'https://imaginai-server-dev.azurewebsites.net/api/v1';

const isProd = true;

export const BASE_URL = isProd ? PROD_URL : DEV_URL;
// export const BASE_URL = "http://192.168.1.83:8080/api/v1";
export const API_KEY = '@x-api-key-ImaginAI-2024-$';
