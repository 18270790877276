import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {fuseArrays} from "../utils/general";
import {TransactionModel} from "../models/transaction.model";

export const useAudioDuration = (audioUrl: string): number | null => {
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    const audio = new Audio(audioUrl);

    const handleLoadedMetadata = () => {
      setDuration(Math.round(audio.duration));
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.load();

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [audioUrl]);

  return duration;
};

export const useNotification = () => {
  const notifications = useSelector((state: RootState) => state.notifications);

  return {
    allNotification: notifications,
    notifications: Object.values(notifications)
  };
};

export const useTransactions = () => {
  const transactions = useSelector(
    (state: RootState) => state.transaction.data
  );

  return {
    allTransactions: transactions,
    transactions: Object.values(transactions) as TransactionModel[]
  };
};

export const useUsers = () => {
  const users = useSelector((state: RootState) => state.users?.data);
  const firebaseUsers = useSelector(
    (state: RootState) => state.users?.firebaseUsers
  );
  let finalArray: any[] = [];

  if (!Object.values(firebaseUsers ?? {})?.length) {
    return {users: Object.values(users)};
  }

  finalArray = useMemo(() => {
    return fuseArrays(
      Object.values(firebaseUsers ?? {}),
      Object.values(users ?? {})
    )?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
  }, [firebaseUsers, users]);
  return {users: finalArray as any[]};
};

export const useVideo = () => {
  const videos = useSelector((state: RootState) => state.video.data);

  return {videos: videos, videosArray: Object.values(videos)};
};
