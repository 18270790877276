export default function DropdownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 20"
      fill="none"
    >
      <path
        d="M10.0004 12.9178C9.8057 12.9182 9.61701 12.8503 9.46708 12.7261L4.46708 8.55944C4.1127 8.26488 4.06419 7.73882 4.35874 7.38444C4.6533 7.03005 5.17936 6.98155 5.53374 7.2761L10.0004 11.0094L14.4671 7.40944C14.6392 7.26963 14.86 7.20421 15.0806 7.22767C15.3011 7.25113 15.5032 7.36154 15.6421 7.53444C15.7964 7.70765 15.8715 7.93733 15.8493 8.16824C15.8272 8.39914 15.7098 8.61036 15.5254 8.7511L10.5254 12.7761C10.3712 12.8807 10.1863 12.9306 10.0004 12.9178Z"
        fill="#637381"
      />
    </svg>
  );
}
