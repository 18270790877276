import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses } from '@mui/material';

export const Cell = styled(TableCell)(({ theme }) => ({
  height: 60,
  color: '#333333',
  fontSize: '16px',
  borderBottom: '1px solid #F3F4F6',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#8392A1'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
