const textEN = {
  new: "new",
  users: "users",
  user: "user",
  admin: "administrator",
  search: "search",
  subscription: "subscription",
  home: "home",
  view: "view",
  creation_date: "creation date",
  name: "name",
  mobile_number: "mobile number",
  status: "status",
  actions: "actions",
  no_data_found: "no data found",
  log_in: "log in",
  log_desc: "enter your email and password to login",
  email: "email",
  password: "password",

  total_users: "total users",
  total_active_users: "total active users",
  number_of_subscriptions: "number of subscriptions",
  new_users: "new users",
  transactions: "transactions",
  total_subscriptions: "total subscriptions",
  total_bronze_package: "total bronze package",
  total_silver_package: "total silver package",
  next: "next",
  previous: "previous",
  subscribers: "subscribers",
  packages: "packages",
  subscriptions: "subscriptions",
  templates: "templates",
  new_template: "new template",
  new_template_desc: "create a new template",
  edit_template: "edit template",
  edit_template_desc: "edit the information of a template",
  french_version: "french version",
  english_version: "english version",
  general_info: "general info",
  fr_title: "french title",
  en_title: "english title",
  fr_instruction: "french instruction",
  en_instruction: "english instruction",
  fr_description: "french description",
  en_description: "french description",
  cancel: "annuler",
  confirm_creation: "confirm creation",
  confirm_modification: "confirm modification",
  save: "save",
  input_type: "input type",
  label: "label",
  class: "class",
  target: "target",
  tag_name: "tag name",
  click_upload: "click to upload",
  drag_drop: "or drag and drop",
  png_required: "PNG, JPG | Required size",
  size_desc: "350x132px",
  language_info: "multilanguage info",
  back: "back",
  high_school: "high school",
  student: "student",
  all: "all",
  other: "other",
  worker: "worker",
  template_details: "template details",
  delete_template: "delete template",
  delete_template_desc:
    "are you sure you want to delete this template? this action is irreversible",
  yes_delete: "yes delete",
  new_admin: "new admin",
  new_system_admin_desc: "create a new system administrator",
  modify_system_admin: "modify administrator",
  modify_system_admin_desc: "modify the information of a system administrator",
  first_name: "first name",
  last_name: "last name",
  username: "username",
  user_details: "user details",
  staff: "staff",
  modify_your_profile_info: "modify your profile informations",
  remove: "remove",
  change: "change",
  templateForm: "template form",
  package_name: "package name",
  duration: "duration",
  month: "month",
  year: "year",
  subscription_details: "subscription details",
  process_exam: "process exam",
  describe_image: "describe image",
  speech_to_text: "speech to text",
  speech_to_instruction: "speech to instruction",
  summarize_text: "summarize text",
  translate_text: "translate text",
  suggest_training_test: "suggest training test",
  extract_text: "extract text",
  suggest_test: "suggest test",
  number_of_input: "number of input",
  edit_subscription: "edit subscription",
  new_subscription: "new subscription",
  delete_subscription: "delete subscription",
  delete_subscription_desc:
    "are you sure you want to delete this subscription plan? this action is irreversible",
  edit_subscription_desc: "edit subscription plan information",
  subscription_info: "subscription information",
  features_info: "features information",
  new_subscription_1: "new subscription",
  new_subscription_desc: "create a new subscription plan",
  subscriptionForm: "subscription form",
  subscription_name: "subscription name",
  monthly_price: "monthly price",
  annual_price: "annual price",
  discounted_annual_price: "discounted annual price",
  activated: "activated",
  allow_all: "allow all",
  recommended: "recommended",
  add_template: "add template",
  add_template_desc: "select the template you want to add to the subscription",
  delete_admin: "delete staff",
  delete_admin_desc:
    "are you sure you want to delete this staff member? this action is irreversible",
  new_user: "new user",
  new_user_desc: "create a new user for the mobile application",
  edit_user: "edit user",
  edit_user_desc: "edit the information of the mobile application user",
  add: "add",
  add_subscribers: "add subscribers",
  add_subscribers_desc: "add subscribers to this package",
  selected: "selected",
  start_date: "start date",
  end_date: "end date",
  tap_add: "tap to add",
  template_playground: "template playground",
  templatePlayground: "template playground",
  date_of_birth: "date of birth",
  new_notification: "new notification",
  new_notification_desc:
    "create a new notification that will be send to all user",
  send: "send",
  notification_text: "notification text",
  subscriptionsPlan: "subscriptions plan",
  title: "title",
  body: "body",
  image: "image",
  total_day_package: "total day package",
  total_confort_package: "total confort package",
  total_pro_package: "total pro package",
  total_elite_package: "total elite package",
  week_1: "1 week",
  day_1: "1 day",
  month_1: "1 month",
  duration_desc: " you will add",
  days: "days",
  files_details: "files details",
  number_of_days_not_connecting: "number of days without openning the app",
  analyse: "analyse",
  notif_send_success: "notification succefully sent",
  treated_operations: "treated operations",
  confirm: "confirm",
  schooLevel: "school level",
  classes: "classes",
  lessons: "lessons",
  upload_csv: "upload csv",

  create_audio: "create an audio",
  create_audio_desc:
    "generate an audio course for the mobile based on the following information",
  select_notion: "select notion",
  level_of_study: "level of study",
  subject: "subject",
  select_notion_desc: "select a notion from this list of notion",
  select_on_what_content_to_generate: "select on what content to generate",
  generate_audio: "generate audio",
  category: "category",
  metrics: "metrics",
  audio_details: "audio details",
  Number_of_daily_subscriptions: "number of daily subscriptions",
  number_of_pro_subscriptions: "number of pro subscriptions",
  number_of_confort_subscriptions: "number of confort subscriptions",
  total_gabon_users: "total gabon users",
  total_togo_users: "total togo users",
  amount_paid: "amount paid",
  creation_date_last_refresh: "creation date / last connexion",
  creation_interval:"creation interval",
  reset:'reset',
};
export default textEN;
