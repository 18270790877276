import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {API_KEY, BASE_URL} from "../config";
import axios from "axios";
import {getAuth} from "firebase/auth";
import notifySuccess from "../utils/notify";

export const initialState = {
  data: {}
} as any;

// ==============================|| SLICE - Video ||============================== //

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    initVideo(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addVideo: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteVideo: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default videoSlice.reducer;

export const {initVideo, addVideo, deleteVideo} = videoSlice.actions;

export const getAllVideoAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const Video = Object.values(getState().video.data);

      const request = {
        url: `${BASE_URL}/videos`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Video.length) {
        const lastOp: any = Video.sort(
          (a: any, b: any) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)
        )[0];

        request.data.updatedAt = lastOp.updatedAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initVideo(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all Video", error);
    }
  };

export const uploadVideoAsync =
  ({
    info,
    callback,
    onError
  }: {
    info: any;
    callback: () => void;
    onError: (ev: any) => void;
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const idToken = await getAuth().currentUser?.getIdToken();
      const formData = new FormData();

      const user = getState().auth.userData;
      const userInfo = {
        id: user?.id,
        photoURL: user?.photoURL ?? "",
        name: "ImaginAi",
        isPublic: true
      } as any;
      formData.append("userInfo", userInfo);

      const fileInfo = {
        uri: info?.file?.uri,
        name: info?.file?.name,
        mime: info?.file?.mime,
        type: info?.file?.mime
      } as any;

      formData.append("file", info?.file);
      formData.append("userID", user.id);
      formData.append("description", info.description);
      formData.append("title", info.title);
      formData.append("tags", info.tags);
      formData.append("metadata", info.metadata);
      formData.append("lang", info.lang);

      const request = {
        url: `${BASE_URL}/videos/analyze`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "multipart/form-data",
          authorization: `Bearer ${idToken}`
        },
        data: formData
      } as any;

      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        notifySuccess("your video is being analysed");
        // dispatch(addVideo(data));
        callback();
      } else {
        console.log("error my dear", error);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error uploading Video", error);
      onError(error);
    }
  };

export const publishVideoItemAsync =
  (data_to_send: any, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const idToken = await getAuth().currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/video/status`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`,
          "x-api-key": API_KEY
        },
        body: JSON.stringify({...data_to_send})
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("video updated succefully", data);
          dispatch(addVideo(data));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error publishing video", error);
    } finally {
    }
  };
