import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch} from ".";
import {auth} from "../firebase";
import {BASE_URL} from "../config";
import {initialLoading2, makingRequest} from "./ui";
import {createUserWithEmailAndPassword} from "firebase/auth";

export const initialState = {
  data: {}
} as any;

// ==============================|| SLICE - ADMINS ||============================== //

const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    initAdmin(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item.id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addAdmin: (state, {payload}) => {
      state.data[payload.id] = payload;
      return state;
    },
    deleteAdmin: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    }
  }
});

export default adminSlice.reducer;

export const {addAdmin, deleteAdmin, initAdmin} = adminSlice.actions;

export const getAllAdminsAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(initialLoading2(true));
      const res = await fetch(`${BASE_URL}/admins`, {
        method: "GET",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        }
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          const ordered = data.map((el: any) => ({
            ...el,
            id: el._id
          }));
          dispatch(initAdmin(ordered));
          return;
        }
        console.log("error getting admins", error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all admins", error);
    } finally {
      dispatch(initialLoading2(false));
    }
  };

export const createAdminAsync =
  (
    formData: any,
    callback?: (id: string) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));
      const idToken = await auth.currentUser?.getIdToken();
      console.log("slice formdata", formData);
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        formData?.username?.toLowerCase() + "@imaginai.io",
        formData.password
      );
      const data_to_send = {
        ...formData,
        id: userCredentials.user.uid,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      console.log("data to send", data_to_send);

      // if (data_to_send.photoURL) {
      //   data_to_send.photoURL = await uploadImage({
      //     file: data_to_send.photoURL,
      //     directory: 'admin'
      //   });
      //   delete data_to_send.photoURL;
      // }
      delete data_to_send.photoURL;

      const res = await fetch(`${BASE_URL}/admins`, {
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data_to_send)
      });
      console.log("request done", res);

      if (res.ok) {
        const {data, error} = await res.json();
        console.log("req ok", data);

        if (data) {
          console.log("the return data", data);
          dispatch(addAdmin(data));
          callback?.(data?._id);
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      } else {
        throw new Error("error creating admin");
      }
    } catch (error) {
      console.log("Error creating admin", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const updateAdminAsync =
  (
    formData: any,
    callback?: (id: string) => void,
    onError?: (el: any) => void
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));
      const idToken = await auth.currentUser?.getIdToken();
      const data_to_send = {
        ...formData,
        updatedAt: new Date()
      };
      console.log("data to send", data_to_send);

      // if (data_to_send.image) {
      //   data_to_send.photoURL = await uploadImage({
      //     file: data_to_send.photoURL,
      //     directory: 'admin'
      //   });
      //   delete data_to_send.photoURL;
      // }
      delete data_to_send.photoURL;
      delete data_to_send.id;

      const res = await fetch(`${BASE_URL}/admins/${formData.id}`, {
        method: "PUT",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        body: JSON.stringify(data_to_send)
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("the return data", data);
          dispatch(addAdmin(data));
          callback?.(data?._id);
          return;
        }
        onError?.(error.message);
        console.log("message", error.message);

        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error updating admin", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };

export const deleteAdminAsync =
  (adminID: string, callback?: () => void, onError?: (el: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));
      const idToken = await auth.currentUser?.getIdToken();

      const res = await fetch(`${BASE_URL}/admins/${adminID}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        }
      });

      if (res.ok) {
        const {data, error} = await res.json();
        if (data) {
          console.log("the return data", data);
          dispatch(deleteAdmin({id: adminID}));
          callback?.();
          return;
        }
        onError?.(error.message);
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error deleting admin", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };
