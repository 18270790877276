import {InputAdornment, InputLabel, OutlinedInput, Stack} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function Search({
  value,
  placeholder,
  onSearch,
  title,
  containerStyle
}: {
  value?: string;
  placeholder?: string;
  containerStyle?: React.CSSProperties;
  title?: string;
  // eslint-disable-next-line no-unused-vars
  onSearch?: (val: string) => void;
}) {
  return (
    <Stack spacing={1}>
      {title && (
        <InputLabel >
          {title} 
        </InputLabel>
      )}
      <OutlinedInput
        fullWidth
        value={value}
        sx={{
          bgcolor: "#fff",
          height: "54px",

          border: "1px solid #D4D4D8",
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent"
          },
          ...containerStyle
        }}
        onChange={(e) => {
          onSearch?.(e.target.value || "");
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={placeholder || "Rechercher"}
      />
    </Stack>
  );
}
