import React from 'react';

export default function EmptyTableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="107"
      viewBox="0 0 107 107"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M40.125 8.9165C28.4442 8.9165 18.9479 18.4128 18.9479 30.0936C18.9479 41.5515 27.9092 50.8248 39.59 51.2261C39.9467 51.1815 40.3033 51.1815 40.5708 51.2261C40.66 51.2261 40.7046 51.2261 40.7937 51.2261C40.8383 51.2261 40.8383 51.2261 40.8829 51.2261C52.2962 50.8248 61.2575 41.5515 61.3021 30.0936C61.3021 18.4128 51.8058 8.9165 40.125 8.9165Z"
        fill="#9AA4B2"
      />
      <path
        d="M62.7733 63.0856C50.3346 54.7931 30.0492 54.7931 17.5213 63.0856C11.8592 66.8752 8.73833 72.0023 8.73833 77.486C8.73833 82.9698 11.8592 88.0523 17.4767 91.7973C23.7183 95.9881 31.9217 98.0835 40.125 98.0835C48.3283 98.0835 56.5317 95.9881 62.7733 91.7973C68.3908 88.0077 71.5117 82.9252 71.5117 77.3968C71.4671 71.9131 68.3908 66.8306 62.7733 63.0856Z"
        fill="#9AA4B2"
      />
      <path
        d="M89.1221 32.7242C89.8354 41.3734 83.6829 48.9525 75.1675 49.978C75.1229 49.978 75.1229 49.978 75.0783 49.978H74.9446C74.6771 49.978 74.4096 49.978 74.1867 50.0671C69.8621 50.29 65.8942 48.908 62.9071 46.3667C67.4992 42.265 70.1296 36.1125 69.5946 29.425C69.2825 25.8138 68.0342 22.5146 66.1617 19.7059C67.8558 18.8588 69.8175 18.3238 71.8238 18.1455C80.5621 17.3875 88.3642 23.8967 89.1221 32.7242Z"
        fill="#9AA4B2"
      />
      <path
        d="M98.0388 73.9639C97.6821 78.2885 94.9179 82.0335 90.2813 84.5747C85.8229 87.0268 80.2054 88.186 74.6325 88.0522C77.8425 85.1543 79.715 81.5431 80.0717 77.7089C80.5175 72.1806 77.8871 66.8751 72.6263 62.6397C69.6392 60.2768 66.1617 58.4043 62.3721 57.0222C72.225 54.1689 84.6192 56.086 92.2429 62.2385C96.3446 65.5376 98.44 69.6839 98.0388 73.9639Z"
        fill="#9AA4B2"
      />
    </svg>
  );
}
