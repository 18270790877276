import React from "react";
import {VideoModel} from "../../../models/video.model";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  Typography,
  Stack,
  TableBody,
  Chip,
  FormControlLabel,
  Switch,
  Button
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Row} from "../../../components/table/Row";
import {capitalizeFirstLetter} from "../../../utils/stringUtils";
import {Cell} from "../../../components/table/Cell";
import i18n from "../../../i18n-config";

interface Props {
  data: VideoModel[];
  onView?: (id: string) => void;
  onPublish: (audioId: string, publish: boolean) => void;
}
export default function VideoTable({data, onView, onPublish}: Props) {
  const {t} = useTranslation();
  return (
    <TableContainer
      component={Box}
      sx={{
        width: 1,
        height: `calc(100% - 160px)`,
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      <Table sx={{minWidth: 100}} aria-label="customized table">
        <TableHead>
          <Row sx={{borderBottom: "1px solid #E5E7EB"}}>
            {columnHeader?.map((col, index: number) => (
              <Cell key={index}>
                <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                  <Typography
                    sx={{
                      ...styles.txt1
                    }}
                  >
                    {capitalizeFirstLetter(t(col))}
                  </Typography>
                </Stack>
              </Cell>
            ))}
          </Row>
        </TableHead>
        <TableBody>
          {data?.map((row, i: number) => (
            <Row
              key={i}
              aria-disabled
              sx={{
                px: 3,
                bgcolor: i % 2 === 0 ? "white" : "#FAFAFA"
              }}
            >
              <Cell component="th" scope="row">
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <video
                    src={row?.videoPreview}
                    style={{height: 50, width: 50}}
                  />
                  <Stack direction={"column"} spacing={1}>
                    <Typography
                      sx={{
                        ...styles.txt2,
                        maxWidth: 300,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis"
                      }}
                    >
                      {row?.title}
                    </Typography>
                    <Typography
                      sx={{
                        ...styles.txt3,
                        maxWidth: 300,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis"
                      }}
                    >
                      {row?.description}
                    </Typography>
                  </Stack>
                </Stack>
              </Cell>
              <Cell align="left">
                <Stack
                spacing={1}
                  sx={{
                    maxWidth: 150
                  }}
                >
                  {row?.typeOfContent.map((el, i) => (
                    <Chip
                      label={capitalizeFirstLetter(el.label?.[i18n.language])}
                      sx={{
                        bgcolor: i % 2 === 0 ? "#FFF6ED" : "#F9F5FF",
                        color: i % 2 === 0 ? "#C4320A" : "#6941C6",
                        fontSize: 12,
                        fontWeight: 500,
                        minWidth: 100
                      }}
                    />
                  ))}
                </Stack>
              </Cell>

              <Cell align="left">
                <Typography sx={styles.txt3}>
                  {row?.metadata?.duration + " secondes"}
                </Typography>
              </Cell>
              <Cell align="left">
                <Typography>{row?.stats.likes + " likes"}</Typography>
                <Typography>{row?.stats.dislikes + " dislikes"}</Typography>
                <Typography>{row?.stats.comments + " comments"}</Typography>
              </Cell>
              <Cell align="left">
                <FormControlLabel
                  control={
                    <Switch
                      checked={row.published}
                      onChange={(ev) => onPublish(row._id, ev.target.checked)}
                    />
                  }
                  label={capitalizeFirstLetter(t(row.published ? "yes" : "no"))}
                />
              </Cell>
              <Cell align="left">
                <Stack direction={"row"} spacing={0.5}>
                  <Button
                    onClick={() => onView?.(row._id + "")}
                    variant="contained"
                    sx={{
                      border: "1px solid #65BF54",
                      bgcolor: "transparent",
                      color: "#65BF54"
                    }}
                  >
                    {capitalizeFirstLetter(t("view"))}
                  </Button>
                </Stack>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = {
  txt1: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#667085"
  },
  txt2: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#202939"
  },
  txt3: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#4B5565"
  }
};

export const columnHeader = [
  "video_info",
  "type_of_content",
  "duration",
  "metrics",
  "status",
  "actions"
];
