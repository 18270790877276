import { forwardRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
// import { useTheme } from '@mui/material/styles';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { RootState } from '../../../store';
import { activeItem } from '../../../store/ui';
import i18n from '../../../i18n-config';
import useGetColors from '../../../hooks/useGetColor';

// project import
// import { activeItem } from 'store/reducers/menu';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }: { item: any; level: any }) => {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const colors = useGetColors();

  const { drawerOpen, openItem } = useSelector((state: RootState) => state.ui);

  let listItemProps = {
    component: forwardRef((props, ref: any) => (
      <Link ref={ref} {...props} to={item.path} />
    ))
  };

  const itemHandler = (id: string | number) => {
    navigate(item?.path);
    dispatch(activeItem({ openItem: [id] }));
  };

  const isSelected =
    openItem.findIndex((id: string | number) => id === item.id) > -1;
  // active menu item on page load
  useEffect(() => {
    if (pathname.includes(item.path)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const Icon = isSelected ? item.activeIcon : item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    false
  );

  const textColor = colors.info.contrastText;
  const iconSelectedColor = colors.info.contrastText;
  const secondColors = colors.secondary.custom;

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        borderRadius: !drawerOpen && 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: isSelected ? '#000000' + 40 : 'primary.lighter'
          },
          '&.Mui-selected': {
            bgcolor: isSelected ? '#000000' + 40 : 'primary.lighter',
            borderRadius: 2,
            // borderRight: `2px solid ${theme.palette.primary}`,
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: isSelected ? secondColors : 'primary.lighter'
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: isSelected ? secondColors : 'primary.lighter'
          },
          '&.Mui-selected': {
            bgcolor: isSelected ? secondColors : 'primary.lighter',
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: isSelected ? secondColors : 'primary.lighter'
            }
          }
        })
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              fontSize: 30,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'secondary.lighter'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: 'primary.lighter',
                '&:hover': {
                  bgcolor: 'primary.lighter'
                }
              })
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: '0.374px',
                ml: 3
              }}
              textTransform="capitalize"
            >
              {i18n.t(item.title)}
            </Typography>
          }
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
