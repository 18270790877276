import { Box, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import i18n from '../../../i18n-config';
import EmptyTableIcon from '../../../assets/icons/users/EmptyTableIcon';

export default function EmptyUser() {
  const style = {
    emptyHeader: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#667085'
    }
  };
  return (
    <Box sx={{ height: '100%' }}>
      <Grid2
        container
        sx={{ p: '12px 24px', borderBottom: '1px solid #E3E8EF' }}
      >
        <Grid2 xs={4}>
          <Typography sx={style.emptyHeader}>
            {capitalizeFirstLetter(i18n.t('name'))}
          </Typography>
        </Grid2>
        <Grid2 xs={2}>
          <Typography sx={style.emptyHeader}>
            {capitalizeFirstLetter(i18n.t('email'))}
          </Typography>
        </Grid2>
        <Grid2 xs={2}>
          <Typography sx={style.emptyHeader}>
            {capitalizeFirstLetter(i18n.t('mobile_number'))}
          </Typography>
        </Grid2>
        <Grid2 xs={2}>
          <Typography sx={style.emptyHeader}>
            {capitalizeFirstLetter(i18n.t('creation_date'))}
          </Typography>
        </Grid2>
        <Grid2 xs={2} sx={{ alignItems: 'end', textAlign: 'end' }}>
          <Typography sx={style.emptyHeader}>
            {capitalizeFirstLetter(i18n.t('status'))}
          </Typography>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          height: '70%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <EmptyTableIcon />
      </Box>
    </Box>
  );
}
