import {createSlice} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from ".";
import {initialLoading6, initialLoading7, makingRequest} from "./ui";
import {BASE_URL} from "../config";
import {getAuth} from "firebase/auth";
import axios from "axios";
import {auth} from "../firebase";

type labelParams = {
  operationData: any;
  onSuccess: (args?: any) => void;
  onError: (args?: any) => void;
};

type labelMultipleParams = {
  operationIDs: any[];
  onSuccess: (args?: any) => void;
  onError: (args?: any) => void;
};

export const initialState = {
  data: {},
  labels: {}
} as any;

// ==============================|| SLICE - CUSTOMERS ||============================== //

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    initFile(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.data = {...state?.data, ...items};
      return state;
    },
    addFile: (state, {payload}) => {
      state.data[payload._id] = payload;
      return state;
    },
    deleteFile: (state, {payload}) => {
      delete state.data[payload.id];
      return state;
    },
    initLabels(state, {payload}) {
      const items: Record<string, any> = {};
      payload.forEach((item: any) => {
        items[item._id] = item;
      });
      state.labels = {...state?.labels, ...items};
      return state;
    },
    addLabel: (state, {payload}) => {
      state.labels[payload._id] = payload;
      return state;
    },
    deleteLabel: (state, {payload}) => {
      delete state.labels[payload.id];
      return state;
    }
  }
});

export default fileSlice.reducer;

export const {
  initFile,
  addFile,
  deleteFile,
  initLabels,
  addLabel,
  deleteLabel
} = fileSlice.actions;

export const getAllFilesAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading6(true));
      const Files = Object.values(getState().file.data);

      const request = {
        url: `${BASE_URL}/recentOperations`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Files.length) {
        const lastOp: any = Files.sort(
          (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        )[Files.length - 1];

        request.data.createdAt = lastOp.createdAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initFile(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all Files", error);
    } finally {
      dispatch(initialLoading6(false));
    }
  };

export const getAllLabelsFilesAsync =
  ({idToken}: {idToken: any}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(initialLoading7(true));
      const Labels = Object.values(getState().file.labels);

      const request = {
        url: `${BASE_URL}/labels`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {}
      } as any;

      if (Labels.length) {
        const lastOp: any = Labels.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
        )[0];

        request.data.createdAt = lastOp.createdAt;
      }
      const {data: resData} = await axios(request);

      const {data, error} = resData;
      if (data) {
        dispatch(initLabels(data));
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.log("Error getting all LABELS", error);
    } finally {
      dispatch(initialLoading7(false));
    }
  };

export const labelFileAsync =
  ({operationData, onSuccess, onError}: labelParams) =>
  async () => {
    try {
      const idToken = await auth.currentUser?.getIdToken();
      const request = {
        url: `${BASE_URL}/labeling`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {...operationData}
      } as any;
      const {data: resData} = await axios(request);
      const {data, error} = resData;
      if (data) {
        onSuccess(data);
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      onError(error);
      console.log("error labeling ressource", error);
    }
  };

export const labelMultipleFilesAsync =
  ({operationIDs, onSuccess, onError}: labelMultipleParams) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(makingRequest(true));
      const idToken = await auth.currentUser?.getIdToken();
      const request = {
        url: `${BASE_URL}/labelMultiple`,
        method: "POST",
        mode: "cors",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${idToken}`
        },
        data: {operationIDs: operationIDs}
      } as any;
      const {data: resData} = await axios(request);
      const {data, error} = resData;
      if (data) {
        const promises = data.map((fileData: any) =>
          dispatch(addLabel(fileData))
        );
        Promise.all(promises);
        onSuccess(data);
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      onError(error);
      console.log("error labeling ressource", error);
    } finally {
      dispatch(makingRequest(false));
    }
  };
